@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&family=Quicksand:wght@300;400;500;600;700&family=Roboto+Serif:ital,opsz,wght@0,8..144,100;0,8..144,200;0,8..144,300;0,8..144,400;0,8..144,500;0,8..144,600;0,8..144,700;0,8..144,800;0,8..144,900;1,8..144,100;1,8..144,200;1,8..144,300;1,8..144,400;1,8..144,500;1,8..144,600;1,8..144,700;1,8..144,800;1,8..144,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
html,
body,
#root {
    height: 100%;
    min-height: 100%;
    width: 100%;
    float: left;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    z-index: 10000;
}

::-webkit-scrollbar-track {
    box-shadow: #fff;
    border-radius: 0;
}

::-webkit-scrollbar-thumb {
    background: #0d47a1;
    border-radius: 0px;
}

.swiper {
    width: 100%;
    height: 100%;
}

.head-slides-img .swiper-slide span {
    height: 100%;
}

.head-slides-img .swiper-slide img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
}

.lazy-load-image-background.blur.lazy-load-image-loaded,
.lazy-load-image-background.blur.lazy-load-image-loaded img {
    max-height: 300px;
    max-width: 300px;
}

img[src=""],
img:not([src]) {
    opacity: 0;
}

img[src="*"] {
    opacity: 1;
}

ins.adsbygoogle[data-ad-status="unfilled"] {
    display: none !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
