.category-custom-small {
    line-height: 0px;
}

.category-custom-small img {
    border-radius: 7px;
}

.avatar-custom img {
    border-radius: 100%;
    margin-top: 8px;
}

#category-admin-choise {
    display: flex;
}

.quill .ql-container {
    height: 350px;
    font-family: Open Sans, sans-serif;
    font-weight: 400;
    font-size: 1rem;
}
